import React, { useState } from 'react'
import axios from "axios";
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import './Login.css';
const backend = process.env.REACT_APP_BACKEND_API;

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
    const signIn = useSignIn();
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        const configuration = {
            method: "post",
            url: backend + "/login",
            data: {
                email,
                password,
            },
        };
        setLoading(true);
        axios(configuration)
        .then((res) => {
            if(signIn(
                {
                    token: res.data.token,
                    expiresIn: 60,
                    tokenType: "Bearer",
                    authState: {"id": res.data.id, "email": res.data.email, "role": res.data.role, "name": res.data.name}
                }
            )){
                if (res.data.changePass) {
                    navigate("/change-password");
                } else {
                    navigate("/");
                }
                //navigate("/");
            }else {
                setLoading(false);
                setErrorMessage(res.data.message || "Erro desconhecido."); 
            }
        })
        .catch((error) => {
            setLoading(false);
            setErrorMessage(error.response?.data?.message || "Erro ao fazer login. Tente novamente."); 
        });
        
    }
    
    return (
        <div className="fullpage">
            <main className="form-signin">
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <img alt="Logo" src="logo512.png" className="logo"/>
                    <h1 className="mb-2">HealthPad</h1>
                    <p>IA para medicina</p>
                    {errorMessage && (
                        <p className="alert alert-danger" role="alert">{errorMessage}</p>
                    )}
                    {loading && (
                        <p className="alert alert-warning" role="alert"> Carregando... </p>
                    )}
                    <div className="form-floating">
                        <input 
                            type={"text"} 
                            disabled={loading}
                            className="form-control" 
                            id="floatingInput" 
                            placeholder="usuario"
                            onChange={(e)=> {
                                     setEmail(e.target.value)
                                     setErrorMessage()
                                     }}
                        />
                        <label htmlFor="floatingInput">Email</label>
                    </div>
                    <div className="form-floating">
                        <input 
                            type={"password"} 
                            disabled={loading}
                            className="form-control" 
                            id="floatingPassword"
                            placeholder="Password"
                            onChange={(e)=> {
                                     setPassword(e.target.value)
                                     setErrorMessage()
                                     }}
                        />
                        <label htmlFor="floatingPassword">Senha</label>
                    </div>
                    <button disabled={loading} className="w-100 btn btn-lg btn-success" type="submit">Login</button>
                    <p className="mt-5 mb-3">&copy; 2024 <a className="text-decoration-none" href="https://www.tbmed.com.br">TBMed Serviços Médicos LTDA.</a></p>
                </form>
            </main>
        </div>
    )
}